import { render, staticRenderFns } from "./Treatment.vue?vue&type=template&id=aa7a5e36&scoped=true"
import script from "./Treatment.vue?vue&type=script&lang=js"
export * from "./Treatment.vue?vue&type=script&lang=js"
import style0 from "./Treatment.vue?vue&type=style&index=0&id=aa7a5e36&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aa7a5e36",
  null
  
)

export default component.exports